import React from 'react'
import styled from 'styled-components'
import { Text, Heading } from '@pancakeswap-libs/uikit'

const StyledHeader = styled.div`

`
const H  = styled(Heading)`
    text-align: center;
    font-size: 1.8rem;
`
const T  = styled(Text)`
    text-align: center;
`

const Header = () => {
    return (
        <StyledHeader>
            <H mb="1rem">Swap at Lowest Fees</H>
            <T>
            Low fees of 0.1% on all swaps!<br/>
0.07% will be split amongst liquidity providers<br/>
and 0.03% will go to PrivacySwap treasury

            <br />
            </T>
        </StyledHeader>
    )
}

export default Header
