import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ButtonMenu, ButtonMenuItem,Button } from '@pancakeswap-libs/uikit'
import TranslatedText from '../TranslatedText'

const StyledNav = styled.div`
  margin-bottom: 10px;
`
const ButtonMenuItemS = styled(ButtonMenuItem)`
display: block;
  @media only screen and (max-width: 550px) {
      display: none;
}
` 

const Nav = ({ activeIndex = 0 }: { activeIndex?: number }) => (
  <StyledNav>
    <ButtonMenu activeIndex={activeIndex}  variant="primary" >
      <ButtonMenuItem id="swap-nav-link" to="/swap" as={Link}>
       <div style={{color:activeIndex === 0?"#000":""}}> <TranslatedText translationId={8}>Swap</TranslatedText></div>
      </ButtonMenuItem>
      <ButtonMenuItem id="pool-nav-link" to="/pool" as={Link}>
      <div style={{color:activeIndex === 1?"#000":""}}><TranslatedText translationId={74}>Liquidity</TranslatedText></div>
      </ButtonMenuItem>
     
    </ButtonMenu>
  </StyledNav>
)

export default Nav
